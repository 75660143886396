<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Medicine
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form action="apps-purchase" method="post" @submit="addSupplyMedicine">
				<b-card>
					<b-row>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Purchase Date<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="purchase_date" rules="required">
									<b-form-datepicker
										name="purchase_date"
										v-model="FormData.purchase_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Farm Name<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Farm Name" rules="required">
										<v-select
											v-model="FormData.farm_id"
											:options="farmList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Medicine <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Select Medicine" rules="required">
										<v-select
											v-model="FormData.medicine_id"
											:options="medicineList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
											@input="getBatchNumberList"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Batch no <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Select Batch no" rules="required">
										<v-select
											v-model="selectBatchList"
											:options="batchList"
											label="batch_no"
											clearable:false
											@input="getBatchDetails"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Expiry date<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name=" Expiry date" rules="required">
									<b-form-datepicker
										v-model="FormData.expiry_date"
										name="expiry_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Stock Available<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Stock Available" rules="required">
										<b-form-input
											v-model="batchDetails.quantity"
											placeholder="Stock Available"
											type="text"
											name="quantity"
											disabled
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Supply Qty<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name=" Supply Qty"
										:rules="{
											required: true,
											max_value: batchDetails.quantity,
										}"
									>
										<b-form-input
											v-model="FormData.quantity"
											placeholder="Supply Qty"
											type="text"
											name="rate_per_pack"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<!-- submit and reset -->
						<b-col md="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Submit </b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
		ValidationProvider,
		ValidationObserver,
	},

	data() {
		return {
			selectedMedicineList: null,
			selectBatchList: null,
			selectFarmList: null,
			FormData: {
				purchase_date: null,
				name: null,
				medicine_id: null,
				expiry_date: null,
				quantity: null,
				stock_available: null,
			},
			batchDetails: [],
			quantity: null,
			medicineList: [],
			batchList: [],
			farmList: [],
			company_id: null,
		};
	},
	created: function () {
		this.userData = JSON.parse(localStorage.getItem("userData"));
		this.getFarmList(this.userData.company.id);
		this.farmId = this.$route.params.farmId;
		this.getMedicines();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getMedicines() {
			axiosIns
				.get(`web/get-all-medicines`)
				.then((response) => {
					this.medicineList = response.data.medicine_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getFarmList(company_id) {
			axiosIns
				.get("web/getFarmList", { params: { company_id: company_id } })
				.then((response) => {
					this.farmList = response.data.farm_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getBatchNumberList(id) {
			axiosIns
				.get(`web/medicine/${id}/batch-list`)
				.then((response) => {
					this.batchList = response.data.batch_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		addSupplyMedicine(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("farm_id", this.FormData.farm_id);
					data.append("medicine_id", this.FormData.medicine_id);
					data.append("batch_no", this.batch_no);
					data.append("expiry_date", this.FormData.expiry_date);
					data.append("stock_available", this.FormData.stock_available);
					data.append("quantity", this.FormData.quantity);
					const farm_id = this.$route.params.farmId;

					axiosIns
						.post(`web/medicine-supply-to-farm`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$router.push({
								name: `apps-supply-farm-list`,
							});
						})
						.catch((error) => {
							const data = error.response.data.errors;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: data,
								},
							});
						});
				}
			});
		},
		getBatchDetails(batchDetails) {
			this.batch_no = batchDetails.batch_no;
			let batch_id = batchDetails.id;
			axiosIns
				.get(`web/medicine/batch/${batch_id}/batch-info`)
				.then((response) => {
					this.batchDetails = response.data.batch_details;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
	background-color: $product-details-bg;
}

.form-item-action-col {
	width: 27px;
}

.repeater-form {
	// overflow: hidden;
	transition: 0.35s height;
}

.v-select {
	&.item-selector-title,
	&.payment-selector {
		background-color: #fff;

		.dark-layout & {
			background-color: unset;
		}
	}
}

.dark-layout {
	.form-item-section {
		background-color: $theme-dark-body-bg;

		.row .border {
			background-color: $theme-dark-card-bg;
		}
	}
}
</style>
